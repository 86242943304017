import React from 'react';
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "./link.js";

export default (props) =>
{
    let illustration = "";
    const styles =
    {
        paddingTop:      30,
        paddingBottom:   30,
        textAlign:       "center",
        backgroundColor: "#EC2024",
        color:           "#eee"
    };
    if(props.first)
    {
        styles.borderTopRightRadius = 6;
        styles.borderTopLeftRadius  = 6;
    }
    if(props.last)
    {
        styles.borderBottomRightRadius = 6;
        styles.borderBottomLeftRadius  = 6;
    }
    
    if(props.illustration)
    {
        illustration = <img src={props.illustration} alt="Zum Productions" style={{
            height:    150,
            marginTop: 30
        }}/>;
    }
    return <>
        <div style={styles}>
        <Container maxWidth="md">
            <Typography variant="h2" component="h2">{props.title}</Typography>
            {illustration}
            <p>{props.txt}</p>
            <Link to={props.url}>
                <Button variant="outlined" color="primary" size="large" style={{marginRight:20,borderColor:"#fff",color:"#fff"}}>{props.cta}</Button>
            </Link>
        </Container></div>
    </>;
};
