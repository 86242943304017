import React,{useState}  from "react";
import Layout from "../../../components/layout.js";
import Section from "../../../components/section.js";
import Cta from "../../../components/cta.js";
import Masthead from "../../../components/masthead.js";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slider from '@material-ui/core/Slider';
import {Container} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "../../../components/link";

export default () =>
{
    const maybePluralize = (count,noun,suffix="s") =>
    {
        let phrase = noun;
        if(count > 1)
        {
            phrase = phrase.replace(/y\s*$/,"");
            phrase += suffix;
        }
        return phrase;
    };
    const marks7 =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 7,
                      label: '7+',
                  }
              ];
    const marks10 =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 5,
                      label: '5',
                  },
                  {
                      value: 10,
                      label: '10+',
                  }
              ];
    const marks16 =
              [
                  {
                      value: 0,
                      label: 'No updates',
                  },
                  {
                      value: 4,
                      label: '4h SLA',
                  },
                  {
                      value: 8,
                      label: '8h SLA',
                  },
                  {
                      value: 12,
                      label: '12h SLA',
                  },
                  {
                      value: 16,
                      label: '16h SLA',
                  }
              ];
    const marks20 =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 5,
                      label: '5',
                  },
                  {
                      value: 10,
                      label: '10',
                  },
                  {
                      value: 15,
                      label: '15',
                  },
                  {
                      value: 20,
                      label: '20+',
                  }
              ];
    const marks50 =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 5,
                      label: '5',
                  },
                  {
                      value: 10,
                      label: '10',
                  },
                  {
                      value: 15,
                      label: '15',
                  },
                  {
                      value: 20,
                      label: '20',
                  },
                  {
                      value: 25,
                      label: '25',
                  },
                  {
                      value: 50,
                      label: '50+',
                  }
              ];
    const marks10000 =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 10,
                      label: '10',
                  },
                  {
                      value: 50,
                      label: '50',
                  },
                  {
                      value: 100,
                      label: '100',
                  },
                  {
                      value: 500,
                      label: '500',
                  },
                  {
                      value: 1000,
                      label: '1000',
                  },
                  {
                      value: 5000,
                      label: '5000',
                  },
                  {
                      value: 10000,
                      label: '10000+',
                  }
              ];
    const marks100k =
              [
                  {
                      value: 1,
                      label: '1',
                  },
                  {
                      value: 20,
                      label: '20',
                  },
                  {
                      value: 50,
                      label: '50',
                  },
                  {
                      value: 100,
                      label: '100',
                  },
                  {
                      value: 500,
                      label: '500',
                  },
                  {
                      value: 1000,
                      label: '1000',
                  },
                  {
                      value: 5000,
                      label: '5000',
                  },
                  {
                      value: 10000,
                      label: '10000',
                  },
                  {
                      value: 50000,
                      label: '50000',
                  },
                  {
                      value: 100000,
                      label: '100000+',
                  }
              ];
    const marksHosting =
              [
                  {
                      value: 100,
                      label: '100',
                  },
                  {
                      value: 500,
                      label: '500',
                  },
                  {
                      value: 1000,
                      label: '1000',
                  },
                  {
                      value: 2000,
                      label: '2000',
                  },
                  {
                      value: 5000,
                      label: '5000',
                  },
                  {
                      value: 10000,
                      label: '10000+',
                  }
              ];

    const [typeAmount,setTypeAmount] = useState(0);
    const [typePrices, setTypePrices]  = useState([0,500,2000]);
    const [typeTotal, setTypeTotal]  = useState(0);
    React.useEffect(() => {
        setTypeTotal(typePrices[typeAmount]);
        switch(typeAmount)
        {
            case 0:
                setDesignAmount(0);
                setCarouselAmount(0);
                setI18nAmount(1);
                setPagesAmount(1);
                setFormAmount(0);
                break;
            case 1:

                break;
            case 2:

                break;
        }
    }, [typeAmount]);

    const [stepstate,setStepstate] = useState(0);
    const [industry,setIndustry] = useState("other");
    const [total,setTotal] = useState(typeTotal);
    const [yearly,setYearly] = useState(0);

    const [comingsoonAmount,setComingsoonAmount] = useState(false);
    const [comingsoonPrices, setComingsoonPrices]  = useState(200);
    const [comingsoonTotal, setComingsoonTotal]  = useState(0);
    React.useEffect(() => {
        setComingsoonTotal(comingsoonPrices*(comingsoonAmount|0));
    }, [comingsoonAmount]);

    const [designAmount,setDesignAmount] = useState(0);
    const [designPrices, setDesignPrices]  = useState([0,2500,10000]);
    const [designTotal, setDesignTotal]  = useState(0);
    React.useEffect(() => {
        setDesignTotal(designPrices[designAmount]);
        switch(designAmount)
        {
            case 0:
                break;
            case 1:
                if(typeAmount < 1)
                {
                    setTypeAmount(1);
                }
                break;
            case 2:
                if(typeAmount < 2)
                {
                    setTypeAmount(2);
                }
                break;
        }
    }, [designAmount]);

    const [responsiveAmount,setResponsiveAmount] = useState(true);
    const [responsivePrices, setResponsivePrices]  = useState(0);
    const [responsiveTotal, setResponsiveTotal]  = useState(0);
    React.useEffect(() => {
        setResponsiveTotal(responsivePrices*responsiveAmount);
    }, [responsiveAmount]);

    const [carouselAmount,setCarouselAmount] = useState(0);
    const [carouselPrices, setCarouselPrices]  = useState(200);
    const [carouselTotal, setCarouselTotal]  = useState(0);
    React.useEffect(() => {
        setCarouselTotal(carouselPrices*carouselAmount);
        if(carouselAmount > 0 && typeAmount < 1)
        {
            setTypeAmount(1);
        }
    }, [carouselAmount]);

    const [pagesAmount,setPagesAmount] = useState(1);
    const [pagesPrices, setPagesPrices]  = useState([200,150,100]);
    const [pagesTotal, setPagesTotal]  = useState(200);
    React.useEffect(() => {
        let total = 0;
        for(let p=1;p<=pagesAmount;p++)
        {
            if(p<=10)
            {
                total += pagesPrices[0];
            }
            else if(p<=25)
            {
                total += pagesPrices[1];
            }
            else
            {
                total += pagesPrices[2];
            }
        }
        setPagesTotal(total);
        if(pagesAmount > 1 && typeAmount < 1)
        {
            setTypeAmount(1);
        }
        if(pagesAmount > 5 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [pagesAmount]);

    const [i18nAmount,setI18nAmount] = useState(1);
    const [i18nPrices, setI18nPrices]  = useState([0,950,200,50]);
    const [i18nTotal, setI18nTotal]  = useState(0);
    React.useEffect(() => {
        let total = 0;
        if(i18nAmount>1)
        {
            total += i18nPrices[1]; // feature enabled
            total += i18nPrices[3]*pagesAmount; // inflate for each page
            total += i18nPrices[3]*carouselAmount; // inflate for each carousel
        }
        if(i18nAmount>2)
        {
            total += i18nPrices[2]*(i18nAmount-2); // additional languages
            total += i18nPrices[3]*pagesAmount*(i18nAmount-2); // inflate for each page
            total += i18nPrices[3]*carouselAmount*(i18nAmount-2); // inflate for each carousel
        }
        setI18nTotal(total);
        if(i18nAmount > 1 && typeAmount < 1)
        {
            setTypeAmount(1);
        }
    }, [i18nAmount,pagesAmount,carouselAmount]);

    const [rtlAmount,setRtlAmount] = useState(false);
    const [rtlPrices, setRtlPrices]  = useState(1000);
    const [rtlTotal, setRtlTotal]  = useState(0);
    React.useEffect(() => {
        setRtlTotal(rtlPrices*rtlAmount);
    }, [rtlAmount]);

    const [translationAmount,setTranslationAmount] = useState(0);
    const [translationPrices, setTranslationPrices]  = useState(100);
    const [translationTotal, setTranslationTotal]  = useState(0);
    React.useEffect(() => {
        setTranslationTotal(translationPrices*translationAmount);
    }, [translationAmount]);

    const [copywAmount,setCopywAmount] = useState(0);
    const [copywPrices, setCopywPrices]  = useState(200);
    const [copywTotal, setCopywTotal]  = useState(0);
    React.useEffect(() => {
        setCopywTotal(copywPrices*copywAmount);
    }, [copywAmount]);

    const [blogAmount,setBlogAmount] = useState(false);
    const [blogPrices, setBlogPrices]  = useState(300);
    const [blogTotal, setBlogTotal]  = useState(0);
    React.useEffect(() => {
        setBlogTotal(blogPrices*blogAmount);
        if(blogAmount === true && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [blogAmount]);

    const [ecommAmount,setEcommAmount] = useState(0);
    const [ecommPrices, setEcommPrices]  = useState([800,50]);
    const [ecommTotal, setEcommTotal]  = useState(0);
    React.useEffect(() => {
        let total = ecommPrices[0];
        total += ecommPrices[1]*ecommAmount;
        setEcommTotal(total);
        if(ecommAmount > 1 && typeAmount < 1)
        {
            setTypeAmount(1);
        }
        if(ecommAmount > 10 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [ecommAmount]);

    const [ratereviewAmount,setRatereviewAmount] = useState(false);
    const [ratereviewPrices, setRatereviewPrices]  = useState(4200);
    const [ratereviewTotal, setRatereviewTotal]  = useState(0);
    React.useEffect(() => {
        setRatereviewTotal(ratereviewPrices*ratereviewAmount);
        if(ratereviewAmount === true && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [ratereviewAmount]);

    const [cmsAmount,setCmsAmount] = useState(false);
    const [cmsPrices, setCmsPrices]  = useState(2000);
    const [cmsTotal, setCmsTotal]  = useState(0);
    React.useEffect(() => {
        setCmsTotal(cmsPrices*cmsAmount);
        if(cmsAmount === true && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [cmsAmount]);

    const [formAmount,setFormAmount] = useState(0);
    const [formPrices, setFormPrices]  = useState(300);
    const [formTotal, setFormTotal]  = useState(0);
    React.useEffect(() => {
        setFormTotal(formPrices*formAmount);
        if(formAmount > 0 && typeAmount < 1)
        {
            setTypeAmount(1);
        }
        if(formAmount > 1 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [formAmount]);

    const [newsletterAmount,setNewsletterAmount] = useState(false);
    const [newsletterPrices, setNewsletterPrices]  = useState(1000);
    const [newsletterTotal, setNewsletterTotal]  = useState(0);
    React.useEffect(() => {
        setNewsletterTotal(newsletterPrices*newsletterAmount);
    }, [newsletterAmount]);

    const [galleryAmount,setGalleryAmount] = useState(0);
    const [galleryPrices, setGalleryPrices]  = useState(300);
    const [galleryTotal, setGalleryTotal]  = useState(0);
    React.useEffect(() => {
        setGalleryTotal(galleryPrices*galleryAmount);
        if(galleryAmount >= 1 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [galleryAmount]);

    const [loginAmount,setLoginAmount] = useState(0);
    const [loginPrices, setLoginPrices]  = useState([0,3200,5000,10000]);
    const [loginTotal, setLoginTotal]  = useState(0);
    React.useEffect(() => {
        setLoginTotal(loginPrices[loginAmount]);
        if(loginAmount > 0 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [loginAmount]);

    const [userprofileAmount,setUserprofileAmount] = useState(false);
    const [userprofilePrices, setUserprofilePrices]  = useState(3700);
    const [userprofileTotal, setUserprofileTotal]  = useState(0);
    React.useEffect(() => {
        setUserprofileTotal(userprofilePrices*userprofileAmount);
        if(userprofileAmount === true && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [userprofileAmount]);

    const [searchAmount,setSearchAmount] = useState(false);
    const [searchPrices, setSearchPrices]  = useState(6100);
    const [searchTotal, setSearchTotal]  = useState(0);
    React.useEffect(() => {
        setSearchTotal(searchPrices*searchAmount);
        if(searchAmount === true && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [searchAmount]);

    const [dbAmount,setDbAmount] = useState(0);
    const [dbPrices, setDbPrices]  = useState([3000,300]);
    const [dbTotal, setDbTotal]  = useState(0);
    React.useEffect(() => {
        let total = 0;
        if(dbAmount>=1)
        {
            total += dbPrices[0];
            total += dbPrices[1]*(dbAmount-1);
        }
        setDbTotal(total);
        if(dbAmount > 0 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [dbAmount]);

    const [apiAmount,setApiAmount] = useState(0);
    const [apiPrices, setApiPrices]  = useState([6000,500]);
    const [apiTotal, setApiTotal]  = useState(0);
    React.useEffect(() => {
        let total = 0;
        if(apiAmount>=1)
        {
            total += apiPrices[0];
            total += apiPrices[1]*(apiAmount-1);
        }
        setApiTotal(total);
        if(apiAmount > 0 && typeAmount < 2)
        {
            setTypeAmount(2);
        }
    }, [apiAmount]);

    const [domainAmount,setDomainAmount] = useState(1);
    const [domainPrices, setDomainPrices]  = useState(20);
    const [domainTotal, setDomainTotal]  = useState(20);
    React.useEffect(() => {
        setDomainTotal(domainPrices*domainAmount);
    }, [domainAmount]);

    const [hostingAmount,setHostingAmount] = useState(1);
    const [hostingPrices, setHostingPrices]  = useState(60);
    const [hostingTotal, setHostingTotal]  = useState(60);
    React.useEffect(() => {
        setHostingTotal(hostingPrices*hostingAmount);
    }, [hostingAmount]);

    const [sslAmount,setSslAmount] = useState(true);
    const [sslPrices, setSslPrices]  = useState(0);
    const [sslTotal, setSslTotal]  = useState(0);
    React.useEffect(() => {
        setSslTotal(sslPrices*sslAmount);
    }, [sslAmount]);

    const [logoAmount,setLogoAmount] = useState(false);
    const [logoPrices, setLogoPrices]  = useState(1500);
    const [logoTotal, setLogoTotal]  = useState(0);
    React.useEffect(() => {
        setLogoTotal(logoPrices*logoAmount);
    }, [logoAmount]);

    const [brandguidelineAmount,setBrandguidelineAmount] = useState(false);
    const [brandguidelinePrices, setBrandguidelinePrices]  = useState(3000);
    const [brandguidelineTotal, setBrandguidelineTotal]  = useState(0);
    React.useEffect(() => {
        setBrandguidelineTotal(brandguidelinePrices*brandguidelineAmount);
    }, [brandguidelineAmount]);

    const [businesscardAmount,setBusinesscardAmount] = useState(0);
    const [businesscardPrices, setBusinesscardPrices]  = useState([600,50]);
    const [businesscardTotal, setBusinesscardTotal]  = useState(0);
    React.useEffect(() => {
        let total = 0;
        if(businesscardAmount>=1)
        {
            total += businesscardPrices[0];
            total += businesscardPrices[1]*(businesscardAmount-1);
        }
        setBusinesscardTotal(total);
    }, [businesscardAmount]);

    const [updatesAmount,setUpdatesAmount] = useState(0);
    const [updatesPrices, setUpdatesPrices]  = useState(1200);
    const [updatesTotal, setUpdatesTotal]  = useState(0);
    React.useEffect(() => {
        setUpdatesTotal(updatesPrices*updatesAmount);
    }, [updatesAmount]);

    const [supportAmount,setSupportAmount] = useState(0);
    const [supportPrices, setSupportPrices]  = useState([0,10000,5000,3000,1800]);
    const [supportTotal, setSupportTotal]  = useState(0);
    React.useEffect(() => {
        setSupportTotal(supportPrices[supportAmount/4]);
    }, [supportAmount]);

    React.useEffect(() =>
    {
        let totals =
        [
            typeTotal,
            comingsoonTotal,
            designTotal,
            responsiveTotal,
            i18nTotal,
            rtlTotal,
            pagesTotal,
            carouselTotal,
            translationTotal,
            copywTotal,
            blogTotal,
            ratereviewTotal,
            cmsTotal,
            formTotal,
            newsletterTotal,
            galleryTotal,
            loginTotal,
            userprofileTotal,
            searchTotal,
            dbTotal,
            apiTotal,
            logoTotal,
            brandguidelineTotal,
            businesscardTotal
        ];
        setTotal(totals.reduce((a,b) => a+b,0));
        setYearly(domainTotal+hostingTotal+sslTotal+updatesTotal+supportTotal);
    },
    [
        typeTotal,
        comingsoonTotal,
        designTotal,
        responsiveTotal,
        i18nTotal,
        rtlTotal,
        pagesTotal,
        carouselTotal,
        translationTotal,
        copywTotal,
        blogTotal,
        ratereviewTotal,
        cmsTotal,
        formTotal,
        newsletterTotal,
        galleryTotal,
        loginTotal,
        userprofileTotal,
        searchTotal,
        dbTotal,
        apiTotal,
        domainTotal,
        hostingTotal,
        sslTotal,
        logoTotal,
        brandguidelineTotal,
        businesscardTotal,
        updatesTotal,
        supportTotal
    ]);

    const steps =
    [
        {
            filter:[0,1,2],
            h:"Category",
            p:"What kind of website do you need? Your choice might be upgraded based on selected options.",
            form:"select",
            value:typeAmount,
            items: ["Introduction page","Informational site","Premium or e-commerce"],
            change:(e) =>
            {
                setTypeAmount(e.target.value);
            }
        },
        {
            filter:[0,1,2],
            h:"Coming soon page",
            p:"Would you like a \"Coming soon\" temporary page while we make your website?",
            form:"switch",
            value:comingsoonAmount,
            change:(e,value) =>
            {
                setComingsoonAmount(value);
            }
        },
        {
            filter:[0,1,2],
            h:"Design",
            p:"What level of design do you expect for your website?",
            form:"select",
            value:designAmount,
            items: [<><b>Template based</b>: Simple yet attractive</>,
                    <><b>Custom design</b>: Inspired by an existing template</>,
                    <><b>High-end</b>: Custom design from scratch</>],
            change:(e) =>
              {
                  setDesignAmount(e.target.value);
              }
        },
        {
            filter:[0,1,2],
            h:"Responsive",
            p:"Do you need your website to work on any device, including smartphones?",
            form:"switch",
            value:responsiveAmount,
            change:(e,value) =>
              {
                  setResponsiveAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Carousel",
            p:"Do you need a carousel or similar introductory slider on your pages?",
            form:"slider",
            value:carouselAmount,
            text:() => carouselAmount+" "+maybePluralize(carouselAmount,"carousel"),
            id:"slider-carousel",
            step:1,
            min:0,
            max:10,
            marks:marks10,
            change:(e,value) =>
            {
                setCarouselAmount(value);
            }
        },
        {
            filter:[0,1,2],
            h:"Internationalization",
            p:"How many languages will you display to your visitors?",
            form:"slider",
            value:i18nAmount,
            text:() => i18nAmount+" "+maybePluralize(i18nAmount,"language"),
            id:"slider-i18n",
            step:1,
            min:1,
            max:50,
            marks:marks50,
            change:(e,value) =>
              {
                  setI18nAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"RTL support",
            p:"Are any of these languages written from right to left?",
            form:"switch",
            value:rtlAmount,
            change:(e,value) =>
              {
                  setRtlAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Pages",
            p:"How many marketing and presentation pages do you want us to design? Please do not count blog articles, shopping carts or any other dynamic content.",
            form:"slider",
            value:pagesAmount,
            text:() => pagesAmount+" "+maybePluralize(pagesAmount,"page"),
            id:"slider-pages",
            step:1,
            min:1,
            max:50,
            marks:marks50,
            change:(e,value) =>
            {
                setPagesAmount(value);
            }
        },
        {
            filter:[0,1,2],
            h:"Translation",
            p:"How many pages do you want us to translate? We employ native speakers. Please multiply the pages by the amount of languages you need translation for. Do not add anything that you wish to translate yourself.",
            form:"slider",
            value:translationAmount,
            text:() => translationAmount+" "+maybePluralize(translationAmount,"page"),
            id:"slider-translation",
            step:1,
            min:0,
            max:50,
            marks:marks50,
            change:(e,value) =>
              {
                  setTranslationAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Copywriting",
            p:"How many pages do need us to write content for? We will do our research on your industry and your company and then help draft a content strategy.",
            form:"slider",
            value:copywAmount,
            text:() => copywAmount+" "+maybePluralize(copywAmount,"page"),
            id:"slider-copyw",
            step:1,
            min:0,
            max:50,
            marks:marks50,
            change:(e,value) =>
              {
                  setCopywAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Blog",
            p:"Add a blog to your website to drive more visitors.",
            form:"switch",
            value:blogAmount,
            change:(e,value) =>
              {
                  setBlogAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"E-Commerce",
            p:"How many articles will you sell? Should you need more, please get in touch so we can have a better idea of your project.",
            form:"slider",
            value:ecommAmount,
            text:() => ecommAmount+" "+maybePluralize(ecommAmount,"article"),
            id:"slider-ecomm",
            step:1,
            min:0,
            max:20,
            marks:marks20,
            change:(e,value) =>
              {
                  setEcommAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Rate and review",
            p:"Would you like your visitors to be able to rate or review something on your website?",
            form:"switch",
            value:ratereviewAmount,
            change:(e,value) =>
              {
                  setRatereviewAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"CMS",
            p:"Do you wish to edit the content yourself later on? Our Content Management System solutions are here to help you. Please note that basic content changes are free of charge upon request, so you most likely do not even need to have a CMS.",
            form:"switch",
            value:cmsAmount,
            change:(e,value) =>
              {
                  setCmsAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Forms",
            p:"How many forms do you wish to track on your website? We do not charge anything for links to your email, phone number or any other contact details. This option is useful to add a contact form on your website.",
            form:"slider",
            value:formAmount,
            text:() => formAmount+" "+maybePluralize(formAmount,"form"),
            id:"slider-form",
            step:1,
            min:0,
            max:10,
            marks:marks10,
            change:(e,value) =>
              {
                  setFormAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Newsletter",
            p:"Do you need a button to let your visitors subscribe to your newsletter? You can combine this option with any compatible third party solution or our own marketing services.",
            form:"switch",
            value:newsletterAmount,
            change:(e,value) =>
              {
                  setNewsletterAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Image/video Gallery",
            p:"How many media galleries would you like to insert on your website? All provided files will be optimized for the internet. For large galleries we charge one gallery for every batch of 100 images or 10 videos.",
            form:"slider",
            value:galleryAmount,
            text:() => galleryAmount+" "+maybePluralize(galleryAmount,"gallery","ies"),
            id:"slider-gallery",
            step:1,
            min:0,
            max:50,
            marks:marks50,
            change:(e,value) =>
              {
                  setGalleryAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Login system",
            p:"Do you want visitors to be able to log into your website somehow? This is particularly useful for community websites or to display information only to specific visitors.",
            form:"select",
            value:loginAmount,
            items: ["No login system",
                    "Email and password",
                    "Social login",
                    "Corporate Single Sign-On (SSO)"],
            change:(e) =>
              {
                  setLoginAmount(e.target.value);
              }
        },
        {
            filter:[0,1,2],
            h:"User Profiles",
            p:"Will your registered users have a profile? The profile pages can be displayed either for other members only or publicly.",
            form:"switch",
            value:userprofileAmount,
            change:(e,value) =>
              {
                  setUserprofileAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Search",
            p:"Do you need to enable search for your visitors? This is usually used on extensive websites, blogs or galleries. Not taking this option will still allow your website to be seen via search engines and it still allows your visitors to user in-page search features from their browsers. This is only a mean to search content on your website.",
            form:"switch",
            value:searchAmount,
            change:(e,value) =>
              {
                  setSearchAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"External database",
            p:"Do you need us to connect to one of your database or API to display important data directly on your website? This option is limited in features, for advanced requests we may suggest to extend this project into a Web app instead of a simple website.",
            form:"slider",
            value:dbAmount,
            text:() => dbAmount+" "+maybePluralize(dbAmount,"query","ies"),
            id:"slider-db",
            step:1,
            min:0,
            max:10,
            marks:marks10,
            change:(e,value) =>
              {
                  setDbAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"API",
            p:"Do you want to expose a REST or GraphQL API so that your other apps (third-party or your own) can programmatically get information out of your website? The pricing in this calculator will not reflect usage overcharges.",
            form:"slider",
            value:apiAmount,
            text:() => apiAmount+" "+maybePluralize(apiAmount,"service"),
            id:"slider-api",
            step:1,
            min:0,
            max:20,
            marks:marks20,
            change:(e,value) =>
              {
                  setApiAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Domain name",
            p:"How many domain names do you want to redirect to your website? The included rate is only for '.com', '.org', '.net', '.ch', '.fr', '.de', '.it' and many country extensions. For special ones such as '.swiss' or '.website' we will give you a separate quote.",
            form:"slider",
            value:domainAmount,
            text:() => domainAmount+" "+maybePluralize(domainAmount,"domain name"),
            id:"slider-domain",
            step:1,
            min:1,
            max:10,
            marks:marks10,
            change:(e,value) =>
              {
                  setDomainAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Hosting",
            p:"Every website needs a home. We have worked hard to optimize a hosting solution that is just right for our technology stack, with plenty of storage and a worldwide delivery network. Don't waste your time with local suppliers, your website will scale with your success. We charge by packages of 100GB of bandwidth, which allows more or less 50'000 page views each depending on the amount of media content you include per page. One package per year minimum.",
            form:"slider",
            value:hostingAmount,
            text:() => hostingAmount*100+" GB of traffic",
            id:"slider-hosting",
            step:1,
            min:1,
            max:100,
            marks:marksHosting,
            change:(e,value) =>
              {
                  setHostingAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"SSL/TLS (HTTPS)",
            p:"Even with a static website it doesn't hurt to improve security, especially when it's free! Should you wish to upgrade to commercial certificates with risk insurance we will gladly accommodate it as well.",
            form:"switch",
            value:sslAmount,
            change:(e,value) =>
              {
                  setSslAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Logo design",
            p:"It may be a good opportunity to talk design while making your website, a great logo goes a long way and may be a great source of inspiration for the entire custom website.",
            form:"switch",
            value:logoAmount,
            change:(e,value) =>
              {
                  setLogoAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Brand design guidelines",
            p:"A document to let your team and your suppliers know how to include your logo and your corporate identity is a key factor to consistency across projects.",
            form:"switch",
            value:brandguidelineAmount,
            change:(e,value) =>
              {
                  setBrandguidelineAmount(value);
              }
        },
        {
            filter:[0,1,2],
            h:"Business cards",
            p:"A great business card always makes an impression. How many people will need one? The first one includes the design and a print of 100 units. Should you need a lot more, please get in touch.",
            form:"slider",
            value:businesscardAmount,
            text:() => businesscardAmount+" "+maybePluralize(businesscardAmount,"Business card"),
            id:"slider-businesscard",
            step:1,
            min:0,
            max:50,
            marks:marks50,
            change:(e,value) =>
              {
                  setBusinesscardAmount(value);
              }
        },
        /*{
            filter:[0,1,2],
            h:"Updates",
            p:"Keeping your website up to date is really important for your data and your users' personal data. Thankfully our own solutions do not need updates from you as we always keep it tight. However, if you picked an open-source CMS it will need to be updated regularly. Most security attacks on vulnerabilities are made after 8h of publishing, so we recommend instant updates upon notification and a weekly round as worst case scenario. Running a business and maintaining a website simultaneously is hard, but we can help. Just pick the right amount of security updates below.",
            form:"slider",
            value:updatesAmount,
            text:() => updatesAmount+" "+maybePluralize(updatesAmount,"update") + " per week",
            id:"slider-updates",
            step:1,
            min:0,
            max:7,
            marks:marks7,
            change:(e,value) =>
              {
                  setUpdatesAmount(value);
              }
        },*/
        {
            filter:[0,1,2],
            h:"Content support services",
            p:"Adding content to your website may seem easy with the right CMS at first, but it's always best to trust a professional. Making sure your latest image is implemented just right where it should be, that all your texts are SEO-friendly and straight to the point and making sure you didn't break any design element is really important. That's why we are offering to make all your changes for you upon request. Subscribing to our services basically gives you a webmaster at hand, just send a brief idea of what you need and we will deliver. Overage may apply if your request implies adding a new feature or adapting an existing one.",
            form:"slider",
            value:supportAmount,
            text:() => supportAmount > 0 ? "Changes are implemented within "+supportAmount+" "+maybePluralize(supportAmount," business hour")+" of your request" : "",
            id:"slider-support",
            step:4,
            min:0,
            max:16,
            marks:marks16,
            change:(e,value) =>
              {
                  setSupportAmount(value);
              }
        },
    ];

    return (<Layout i18n="en" languages={[]} menulinks={[]}>
        <Masthead illustration="/img/undraw/svg-ec2024/undraw_Calculator_0evy.svg"
                  title="Websites Cost Calculator"
                  subtitle="We're not wasting your time and we won't ask vague questions"
                  cta1txt="Get in touch"
                  cta1url={"https://zumproductions.atlassian.net/servicedesk/customer/portal/1"}
                  cta2txt="Browse packages"
                  cta2url="/en/websites/packages"
        />
        <Section>
            <Container maxWidth="md" style={{position:"relative",paddingTop:40,paddingBottom:100}}>
                {
                    steps.map((step,index) =>
                    {
                        if(index === stepstate && step.filter.indexOf(typeAmount)>=0)
                        {
                            return <>
                                <Typography variant="h4" component="h4">{step.h}</Typography>
                                <p>{step.p}</p>
                                {
                                    step.form === "select" &&
                                    (
                                        <Select value={step.value} onChange={step.change}>
                                        {
                                            step.items.map((item,index) =>
                                                (<MenuItem value={index}>{item}</MenuItem>)
                                            )
                                        }
                                        </Select>
                                    )
                                }
                                {
                                    step.form === "slider" &&
                                    (
                                        <>
                                            <Typography id={step.id} gutterBottom>
                                                {step.text()}
                                            </Typography>
                                            <Slider
                                                defaultValue={step.value}
                                                getAriaValueText={step.text}
                                                aria-labelledby={step.id}
                                                step={step.step}
                                                min={step.min}
                                                max={step.max}
                                                onChange={step.change}
                                                marks={step.marks}
                                                valueLabelDisplay="on"
                                                style={{marginTop:30}}
                                            />
                                        </>
                                    )
                                }

                                {
                                    step.form === "switch" &&
                                    (
                                        <Switch
                                            checked={step.value}
                                            onChange={step.change}
                                            value={true}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    )
                                }
                            </>
                        }
                    })
                }
                <span style={{position:"absolute",bottom:30,right:0}}>{stepstate+1}/{steps.length}</span>
                {stepstate<steps.length-1 &&
                    <span style={{position:"absolute",top:30,right:0}}>
                        <Button size="large" variant="contained" onClick={() => {setStepstate(stepstate+1)}}>Next</Button>
                    </span>
                }
                {stepstate>0 &&
                    <span style={{position:"absolute",top:30,left:0}}>
                        <Button size="large" variant="contained" onClick={() => {setStepstate(stepstate-1)}}>Back</Button>
                    </span>
                }
                {stepstate>1 &&
                 <span style={{position:"absolute",top:30,left:0}}>
                        <Button size="large" variant="contained" onClick={() => {setStepstate(stepstate-1)}}>Back</Button>
                    </span>
                }
                {stepstate===steps.length-1 &&
                 <span style={{position:"absolute",top:30,right:0}}>
                        <Button href="#price-estimates" size="large" color="primary" variant="contained">Estimates</Button>
                    </span>
                }
            </Container>
        </Section>
        <Section id="price-estimates">
            <Container maxWidth="md" style={{position:"relative",paddingTop:50,paddingBottom:50}}>
                <Typography variant="h4" component="h4">Your quote*</Typography>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{backgroundColor:"#cdcdcd"}}>Options</TableCell>
                                <TableCell style={{backgroundColor:"#cdcdcd"}} align="right">Quantity</TableCell>
                                <TableCell style={{backgroundColor:"#cdcdcd"}} align="right">Unit</TableCell>
                                <TableCell style={{backgroundColor:"#cdcdcd"}} align="right">Setup price</TableCell>
                                <TableCell style={{backgroundColor:"#cdcdcd"}} align="right">Yearly</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"#f5f5f5"}}>
                            <TableRow>
                                <TableCell>Project setup</TableCell>
                                <TableCell align="right">1</TableCell>
                                <TableCell align="right">{(() =>
                                    {
                                        switch(typeAmount)
                                        {
                                            case 0: return "Introduction page";
                                            case 1: return "Informational site";
                                            case 2: return "Premium or e-commerce";
                                            default: return "";
                                        }
                                    })()}
                                </TableCell>
                                <TableCell align="right">{typeTotal}</TableCell>
                                <TableCell />
                            </TableRow>
                            {comingsoonAmount &&
                             <TableRow> <TableCell>Coming soon page</TableCell>
                                 <TableCell align="right">1</TableCell>
                                 <TableCell align="right">page</TableCell>
                                 <TableCell align="right">{comingsoonTotal}</TableCell>
                                 <TableCell/>
                             </TableRow>
                            }
                            <TableRow>
                                <TableCell>Design</TableCell>
                                <TableCell align="right">{(() =>
                                {
                                    switch(designAmount)
                                    {
                                        case 0: return "1";
                                        case 1: return "3";
                                        case 2: return "5";
                                        default: return "";
                                    }
                                })()}
                                </TableCell>
                                <TableCell align="right">{(() =>
                                    {
                                        switch(designAmount)
                                        {
                                            case 0: return "Template";
                                            case 1: return "Custom design iterations";
                                            case 2: return "High-end design iterations";
                                            default: return "";
                                        }
                                    })()}
                                </TableCell>
                                <TableCell align="right">{designTotal}</TableCell>
                                <TableCell />
                            </TableRow>
                            {responsiveAmount &&
                                <TableRow> <TableCell>Responsive</TableCell>
                                    <TableCell align="right">1</TableCell>
                                    <TableCell align="right">design</TableCell>
                                    <TableCell align="right">{responsiveTotal}</TableCell>
                                    <TableCell/>
                                </TableRow>
                            }
                            {carouselAmount>0 &&
                             <TableRow> <TableCell>Carousel</TableCell>
                                 <TableCell align="right">{carouselAmount}</TableCell>
                                 <TableCell align="right">{maybePluralize(carouselAmount,"carousel")}</TableCell>
                                 <TableCell align="right">{carouselTotal}</TableCell>
                                 <TableCell/>
                             </TableRow>
                            }
                            <TableRow>
                                <TableCell>Internationalization</TableCell>
                                <TableCell align="right">{i18nAmount}</TableCell>
                                <TableCell align="right">{maybePluralize(i18nAmount,"language")}</TableCell>
                                <TableCell align="right">{i18nTotal}</TableCell>
                                <TableCell/>
                            </TableRow>
                            {rtlAmount &&
                                 <TableRow>
                                     <TableCell>RTL support</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right"></TableCell>
                                     <TableCell align="right">{rtlTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            <TableRow>
                                <TableCell>Pages</TableCell>
                                <TableCell align="right">{pagesAmount}</TableCell>
                                <TableCell align="right">{maybePluralize(pagesAmount,"page")} of 5000 characters max</TableCell>
                                <TableCell align="right">{pagesTotal}</TableCell>
                                <TableCell/>
                            </TableRow>
                            {translationAmount>0 &&
                                 <TableRow>
                                     <TableCell>Translation</TableCell>
                                     <TableCell align="right">{translationAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(translationAmount,"page")}</TableCell>
                                     <TableCell align="right">{translationTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {copywAmount>0 &&
                                 <TableRow>
                                     <TableCell>Copywriting</TableCell>
                                     <TableCell align="right">{copywAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(copywAmount,"page")} of 500 words max</TableCell>
                                     <TableCell align="right">{copywTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {blogAmount &&
                                 <TableRow>
                                     <TableCell>Blog</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">Blog</TableCell>
                                     <TableCell align="right">{blogTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {ecommAmount>0 &&
                                 <TableRow>
                                     <TableCell>E-Commerce</TableCell>
                                     <TableCell align="right">{ecommAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(ecommAmount,"article")}</TableCell>
                                     <TableCell align="right">{ecommTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {ratereviewAmount &&
                                 <TableRow>
                                     <TableCell>Rate and review</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">feature</TableCell>
                                     <TableCell align="right">{ratereviewTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {cmsAmount &&
                                 <TableRow>
                                     <TableCell>CMS</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">CMS</TableCell>
                                     <TableCell align="right">{cmsTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {formAmount>0 &&
                                 <TableRow>
                                     <TableCell>Forms</TableCell>
                                     <TableCell align="right">{formAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(formAmount,"form")}</TableCell>
                                     <TableCell align="right">{formTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {newsletterAmount &&
                                 <TableRow>
                                     <TableCell>Newsletter</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">form</TableCell>
                                     <TableCell align="right">{newsletterTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {galleryAmount>0 &&
                                 <TableRow>
                                     <TableCell>Image/video Gallery</TableCell>
                                     <TableCell align="right">{galleryAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(galleryAmount,"gallery","ies")} of max 100 images or 10 videos</TableCell>
                                     <TableCell align="right">{galleryTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {loginAmount>0 &&
                                 <TableRow>
                                     <TableCell>Login system</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">{(() =>
                                     {
                                         switch(loginAmount)
                                         {
                                             case 0: return "No login system";
                                             case 1: return "Email and password";
                                             case 2: return "Social login";
                                             case 3: return "Corporate Single Sign-On (SSO)";
                                             default: return "";
                                         }
                                     })()}
                                     </TableCell>
                                     <TableCell align="right">{loginTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {userprofileAmount &&
                                 <TableRow>
                                     <TableCell>User Profiles</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">feature</TableCell>
                                     <TableCell align="right">{userprofileTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {searchAmount &&
                                 <TableRow>
                                     <TableCell>Search</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">feature</TableCell>
                                     <TableCell align="right">{searchTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {dbAmount>0 &&
                                 <TableRow>
                                     <TableCell>External database</TableCell>
                                     <TableCell align="right">{dbAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(dbAmount,"query","ies")}</TableCell>
                                     <TableCell align="right">{dbTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {apiAmount>0 &&
                                 <TableRow>
                                     <TableCell>API</TableCell>
                                     <TableCell align="right">{apiAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(apiAmount,"service")}</TableCell>
                                     <TableCell align="right">{apiTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {domainAmount>0 &&
                                 <TableRow>
                                     <TableCell>Domain name</TableCell>
                                     <TableCell align="right">{domainAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(domainAmount,"domain name")}</TableCell>
                                     <TableCell align="right"></TableCell>
                                     <TableCell align="right">{domainTotal}</TableCell>
                                 </TableRow>
                            }
                            {hostingAmount>0 &&
                                 <TableRow>
                                     <TableCell>Hosting</TableCell>
                                     <TableCell align="right">{hostingAmount}</TableCell>
                                     <TableCell align="right">GB of traffic</TableCell>
                                     <TableCell align="right"></TableCell>
                                     <TableCell align="right">{hostingTotal}</TableCell>
                                 </TableRow>
                            }
                            {sslAmount &&
                                 <TableRow>
                                     <TableCell>SSL/TLS (HTTPS)</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">certificate</TableCell>
                                     <TableCell align="right"></TableCell>
                                     <TableCell align="right">{sslTotal}</TableCell>
                                 </TableRow>
                            }
                            {logoAmount &&
                                 <TableRow>
                                     <TableCell>Logo design</TableCell>
                                     <TableCell align="right">up to 3</TableCell>
                                     <TableCell align="right">logo iterations</TableCell>
                                     <TableCell align="right">{logoTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {brandguidelineAmount &&
                                 <TableRow>
                                     <TableCell>Brand design guidelines</TableCell>
                                     <TableCell align="right">1</TableCell>
                                     <TableCell align="right">document</TableCell>
                                     <TableCell align="right">{brandguidelineTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {businesscardAmount>0 &&
                                 <TableRow>
                                     <TableCell>Business cards</TableCell>
                                     <TableCell align="right">{businesscardAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(businesscardAmount,"Business card")}</TableCell>
                                     <TableCell align="right">{businesscardTotal}</TableCell>
                                     <TableCell/>
                                 </TableRow>
                            }
                            {updatesAmount>0 &&
                                 <TableRow>
                                     <TableCell>Updates</TableCell>
                                     <TableCell align="right">{updatesAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(updatesAmount,"update") + " per week"}</TableCell>
                                     <TableCell/>
                                     <TableCell align="right">{updatesTotal}</TableCell>
                                 </TableRow>
                            }
                            {supportAmount>0 &&
                                 <TableRow>
                                     <TableCell>Content support services</TableCell>
                                     <TableCell align="right">{supportAmount}</TableCell>
                                     <TableCell align="right">{maybePluralize(supportAmount,"Business Hour")+" SLA"}</TableCell>
                                     <TableCell/>
                                     <TableCell align="right">{supportTotal}</TableCell>
                                 </TableRow>
                            }

                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell align="right" style={{fontWeight:"bold"}}>Total*</TableCell>
                                <TableCell align="right" style={{fontWeight:"bold"}}>CHF {total}.-</TableCell>
                                <TableCell align="right" style={{fontWeight:"bold"}}>CHF {yearly}.-</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
                <p>We like to iterate and will be able to accommodate project milestones or additional features upon completion of the initial project.</p>
                {false && <Link to={
                    "/en/websites/packages"
                } style={{
                    color:          "inherit",
                    textDecoration: "none"}}>
                    <Button color="primary" variant="outlined" style={{marginRight:20}}>Browse current offers</Button>
                </Link>}
                <a target="_blank" href={
                    "https://zumproductions.atlassian.net/servicedesk/customer/portal/1/group/1/create/10?environment="
                    +"Type%3A%20"+typeAmount+"%0a"
                    +"Coming%20soon%20page%3A%20"+comingsoonAmount+"%0a"
                    +"Design%3A%20"+designAmount+"%0a"
                    +"Responsive%3A%20"+responsiveAmount+"%0a"
                    +"Languages%3A%20"+i18nAmount+"%0a"
                    +"RTL%3A%20"+rtlAmount+"%0a"
                    +"Pages%3A%20"+pagesAmount+"%0a"
                    +"Carousels%3A%20"+carouselAmount+"%0a"
                    +"Translations%3A%20"+translationAmount+"%0a"
                    +"Copywritting%3A%20"+copywAmount+"%0a"
                    +"Blog%3A%20"+blogAmount+"%0a"
                    +"Rate%20and%20review%3A%20"+ratereviewAmount+"%0a"
                    +"CMS%3A%20"+cmsAmount+"%0a"
                    +"Forms%3A%20"+formAmount+"%0a"
                    +"Newsletter%3A%20"+newsletterAmount+"%0a"
                    +"Galleries%3A%20"+galleryAmount+"%0a"
                    +"Login%3A%20"+loginAmount+"%0a"
                    +"User%20profile%3A%20"+userprofileAmount+"%0a"
                    +"Search%3A%20"+searchAmount+"%0a"
                    +"External%20databases%3A%20"+dbAmount+"%0a"
                    +"API%3A%20"+apiAmount+"%0a"
                    +"Domains%3A%20"+domainAmount+"%0a"
                    +"Hosting%20bandwidth%3A%20"+hostingAmount+"%0a"
                    +"SSL%3A%20"+sslAmount+"%0a"
                    +"Logo%3A%20"+logoAmount+"%0a"
                    +"Brand%20guideline%3A%20"+brandguidelineAmount+"%0a"
                    +"Business%20cards%3A%20"+businesscardAmount+"%0a"
                    +"Updates%20SLA%3A%20"+updatesAmount+"%0a"
                    +"Support%20SLA%3A%20"+supportAmount+"%0a"
                    +"Estimated%20total%3A%20"+total+"%0a"
                    +"Estimated%20yearly%20fees%3A%20"+yearly
                } style={{
                    color:          "inherit",
                    textDecoration: "none"}}>
                    <Button color="primary" variant="contained">Request a final quote</Button>
                </a>
                <p><strong>* This quote has been generated automatically and should be construed only as an estimate.</strong> The information and descriptions of features and services contained herein are not intended to be complete descriptions of all terms, conditions, limitations, or exclusions, and are provided solely for general informational purposes. Products, rates, plans and services described in this website may vary based on the specifications of your project, company, industry, location and applicable laws.</p>
            </Container>
        </Section>
        <Section>
            <Cta illustration=""
                 //title="Still not sure what you need?"
                 txt="If you need assistance or a custom offer, we will gladly answer all your questions."
                 url="https://zumproductions.atlassian.net/servicedesk/customer/portal/1"
                 cta="Visit our Help Center"
                 first={true}
                 last={true}
            />
        </Section>
    </Layout>);
}
